<template>
    <div>
        <div id="wrapper">
            <input class="searchBar" type="text" v-model="search" placeholder="Search for Nikkes...">
            <div class="nikkes">
                <router-link v-for="nikke in filteredNikkes" :key="nikke.name" :to="`/nikke/${nikke.name}`">
                    <a>
                        <div class="nikke" :data-image="nikke.image">
                            <div class="image" :style="{ backgroundImage: `url(${nikke.image})` }"></div>
                            <div class="name">{{ nikke.name }}</div>
                            <br>
                            <div class="burst" :style="{ backgroundImage: `url(${nikke.burst})` }"></div>
                            <div class="class" :style="{ backgroundImage: `url(${nikke.class})` }"></div>
                            <div class="code" :style="{ backgroundImage: `url(${nikke.code})` }"></div>
                            <div class="rarity" :style="{ backgroundImage: `url(${nikke.rarity})` }"></div>
                            <div class="weapon" :style="{ backgroundImage: `url(${nikke.weapon})` }"></div>
                            <div class="company" :style="{ backgroundImage: `url(${nikke.company})` }"></div>
                        </div>
                    </a>
                </router-link>
            </div>
        </div>
        <div id="ad-section" style="text-align: center; margin-top: 30px;">
            <div id="ad-container"></div>
        </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
            nikkes: [
                { name: "2B", burst: "burst3", class: "defender", code: "fire", company: "", rarity: "ssr", weapon: "ar" },
                { name: "A2", burst: "burst3", class: "attacker", code: "fire", company: "", rarity: "ssr", weapon: "rl" },
                { name: "Admi", burst: "burst2", class: "supporter", code: "wind", company: "missilis", rarity: "ssr", weapon: "sr" },
                { name: "Alice", burst: "burst3", class: "attacker", code: "fire", company: "tetra", rarity: "ssr", weapon: "sr" },
                { name: "Anchor", burst: "burst1", class: "defender", code: "wind", company: "elysion", rarity: "sr", weapon: "rl" },
                { name: "Anis", burst: "burst2", class: "defender", code: "iron", company: "tetra", rarity: "sr", weapon: "rl" },
                { name: "Anis: Sparkling Summer", burst: "burst3", class: "supporter", code: "electric", company: "tetra", rarity: "ssr", weapon: "rl" },
                { name: "Anne: Miracle Fairy", burst: "burst2", class: "supporter", code: "wind", company: "missilis", rarity: "ssr", weapon: "rl" },
                { name: "Aria", burst: "burst2", class: "attacker", code: "water", company: "tetra", rarity: "ssr", weapon: "mg" },
                { name: "Belorta", burst: "burst2", class: "attacker", code: "electric", company: "tetra", rarity: "sr", weapon: "rl" },
                { name: "Biscuit", burst: "burst2", class: "supporter", code: "electric", company: "tetra", rarity: "ssr", weapon: "rl" },
                { name: "blanc", burst: "burst2", class: "defender", code: "wind", company: "tetra", rarity: "ssr", weapon: "ar" },
                { name: "Brid", burst: "burst3", class: "attacker", code: "water", company: "elysion", rarity: "ssr", weapon: "ar" },
                { name: "Centi", burst: "burst2", class: "defender", code: "iron", company: "missilis", rarity: "ssr", weapon: "rl" },
                { name: "Cocoa", burst: "burst1", class: "supporter", code: "fire", company: "tetra", rarity: "ssr", weapon: "sr" },
                { name: "Crow", burst: "burst3", class: "defender", code: "fire", company: "missilis", rarity: "ssr", weapon: "smg" },
                { name: "D", burst: "burst3", class: "attacker", code: "wind", company: "elysion", rarity: "ssr", weapon: "smg" },
                { name: "Delta", burst: "burst2", class: "defender", code: "wind", company: "elysion", rarity: "ssr", weapon: "sr" },
                { name: "Diesel", burst: "burst2", class: "defender", code: "wind", company: "elysion", rarity: "ssr", weapon: "mg" },
                { name: "Dolla", burst: "burst2", class: "supporter", code: "wind", company: "tetra", rarity: "ssr", weapon: "sr" },
                { name: "Dorothy", burst: "burst1", class: "supporter", code: "water", company: "pilgrim", rarity: "ssr", weapon: "ar" },
                { name: "Drake", burst: "burst3", class: "attacker", code: "fire", company: "missilis", rarity: "ssr", weapon: "sg" },
                { name: "Emma", burst: "burst1", class: "supporter", code: "fire", company: "elysion", rarity: "ssr", weapon: "mg" },
                { name: "Epinel", burst: "burst3", class: "attacker", code: "wind", company: "missilis", rarity: "ssr", weapon: "rl" },
                { name: "Ether", burst: "burst1", class: "defender", code: "electric", company: "missilis", rarity: "sr", weapon: "sg" },
                { name: "Eunhwa", burst: "burst2", class: "attacker", code: "fire", company: "elysion", rarity: "ssr", weapon: "sr" },
                { name: "Exia", burst: "burst1", class: "supporter", code: "electric", company: "tetra", rarity: "ssr", weapon: "sr" },
                { name: "Folkwang", burst: "burst2", class: "defender", code: "water", company: "tetra", rarity: "ssr", weapon: "ar" },
                { name: "Frima", burst: "burst1", class: "supporter", code: "iron", company: "tetra", rarity: "ssr", weapon: "sr" },
                { name: "Guillotine", burst: "burst3", class: "attacker", code: "electric", company: "elysion", rarity: "ssr", weapon: "mg" },
                { name: "Guilty", burst: "burst2", class: "attacker", code: "wind", company: "missilis", rarity: "ssr", weapon: "sg" },
                { name: "Harran", burst: "burst3", class: "attacker", code: "electric", company: "pilgrim", rarity: "ssr", weapon: "sr" },
                { name: "Helm", burst: "burst3", class: "attacker", code: "water", company: "elysion", rarity: "ssr", weapon: "sr" },
                { name: "Helm: Aquamarine", burst: "burst2", class: "attacker", code: "iron", company: "elysion", rarity: "ssr", weapon: "ar" },
                { name: "Himeno", burst: "burst2", class: "supporter", code: "wind", company: "", rarity: "sr", weapon: "sr" },
                { name: "iDoll Flower", burst: "burst1", class: "defender", code: "wind", company: "tetra", rarity: "r", weapon: "rl" },
                { name: "iDoll Ocean", burst: "burst1", class: "supporter", code: "water", company: "tetra", rarity: "r", weapon: "smg" },
                { name: "iDoll Sun", burst: "burst3", class: "supporter", code: "iron", company: "tetra", rarity: "r", weapon: "ar" },
                { name: "Isabel", burst: "burst3", class: "attacker", code: "electric", company: "pilgrim", rarity: "ssr", weapon: "sg" },
                { name: "Jackal", burst: "burst1", class: "defender", code: "iron", company: "missilis", rarity: "ssr", weapon: "rl" },
                { name: "Julia", burst: "burst3", class: "attacker", code: "iron", company: "missilis", rarity: "ssr", weapon: "ar" },
                { name: "Laplace", burst: "burst3", class: "attacker", code: "iron", company: "missilis", rarity: "ssr", weapon: "rl" },
                { name: "Liter", burst: "burst1", class: "supporter", code: "iron", company: "missilis", rarity: "ssr", weapon: "smg" },
                { name: "Ludmilla", burst: "burst1", class: "defender", code: "water", company: "tetra", rarity: "ssr", weapon: "smg" },
                { name: "Maiden", burst: "burst3", class: "attacker", code: "electric", company: "elysion", rarity: "ssr", weapon: "sg" },
                { name: "Makima", burst: "burst2", class: "defender", code: "water", company: "", rarity: "ssr", weapon: "smg" },
                { name: "Marciana", burst: "burst2", class: "supporter", code: "iron", company: "elysion", rarity: "ssr", weapon: "sg" },
                { name: "Marian", burst: "None", class: "None", code: "None", company: "None", rarity: "None", weapon: "None" },
                { name: "Mary", burst: "burst1", class: "supporter", code: "water", company: "tetra", rarity: "ssr", weapon: "sg" },
                { name: "Mary: Bay Goddess", burst: "burst1", class: "supporter", code: "water", company: "tetra", rarity: "ssr", weapon: "sr" },
                { name: "Mast", burst: "burst2", class: "supporter", code: "electric", company: "elysion", rarity: "ssr", weapon: "smg" },
                { name: "Maxwell", burst: "burst3", class: "attacker", code: "iron", company: "missilis", rarity: "ssr", weapon: "sr" },
                { name: "Mica", burst: "burst1", class: "supporter", code: "wind", company: "tetra", rarity: "sr", weapon: "rl" },
                { name: "Mihara", burst: "burst2", class: "defender", code: "water", company: "missilis", rarity: "sr", weapon: "ar" },
                { name: "Milk", burst: "burst1", class: "attacker", code: "water", company: "tetra", rarity: "ssr", weapon: "sr" },
                { name: "Miranda", burst: "burst1", class: "supporter", code: "fire", company: "elysion", rarity: "ssr", weapon: "ar" },
                { name: "Modernia", burst: "burst3", class: "attacker", code: "fire", company: "pilgrim", rarity: "ssr", weapon: "mg" },
                { name: "N102", burst: "burst1", class: "supporter", code: "water", company: "missilis", rarity: "sr", weapon: "rl" },
                { name: "Naga", burst: "burst2", class: "supporter", code: "electric", company: "missilis", rarity: "ssr", weapon: "sg" },
                { name: "Neon", burst: "burst1", class: "supporter", code: "fire", company: "elysion", rarity: "sr", weapon: "sg" },
                { name: "Neon: Blue Ocean", burst: "burst3", class: "attacker", code: "water", company: "elysion", rarity: "ssr", weapon: "mg" },
                { name: "Nero", burst: "burst2", class: "defender", code: "fire", company: "tetra", rarity: "ssr", weapon: "smg" },
                { name: "Neve", burst: "burst3", class: "attacker", code: "water", company: "tetra", rarity: "sr", weapon: "sg" },
                { name: "Nihilister", burst: "burst2", class: "attacker", code: "fire", company: "pilgrim", rarity: "ssr", weapon: "sr" },
                { name: "Noah", burst: "burst2", class: "defender", code: "wind", company: "pilgrim", rarity: "ssr", weapon: "rl" },
                { name: "Noir", burst: "burst3", class: "attacker", code: "wind", company: "tetra", rarity: "ssr", weapon: "sg" },
                { name: "Noise", burst: "burst2", class: "supporter", code: "electric", company: "tetra", rarity: "ssr", weapon: "rl" },
                { name: "Novel", burst: "burst2", class: "defender", code: "iron", company: "tetra", rarity: "ssr", weapon: "smg" },
                { name: "Pascal", burst: "burst1", class: "supporter", code: "iron", company: "", rarity: "sr", weapon: "rl" },
                { name: "Pepper", burst: "burst1", class: "supporter", code: "wind", company: "missilis", rarity: "ssr", weapon: "sg" },
                { name: "Poli", burst: "burst2", class: "defender", code: "water", company: "elysion", rarity: "ssr", weapon: "sg" },
                { name: "Power", burst: "burst3", class: "attacker", code: "fire", company: "", rarity: "ssr", weapon: "rl" },
                { name: "Privaty", burst: "burst3", class: "attacker", code: "water", company: "elysion", rarity: "ssr", weapon: "ar" },
                { name: "Product 08", burst: "burst1", class: "supporter", code: "electric", company: "missilis", rarity: "r", weapon: "sr" },
                { name: "Product 12", burst: "burst3", class: "attacker", code: "fire", company: "missilis", rarity: "r", weapon: "mg" },
                { name: "Product 23", burst: "burst2", class: "supporter", code: "wind", company: "missilis", rarity: "r", weapon: "sg" },
                { name: "Quency", burst: "burst2", class: "supporter", code: "electric", company: "missilis", rarity: "ssr", weapon: "smg" },
                { name: "Quiry", burst: "burst3", class: "supporter", code: "wind", company: "elysion", rarity: "ssr", weapon: "rl" },
                { name: "Rapi", burst: "burst3", class: "attacker", code: "fire", company: "elysion", rarity: "sr", weapon: "ar" },
                { name: "Rapunzel", burst: "burst1", class: "supporter", code: "iron", company: "pilgrim", rarity: "ssr", weapon: "rl" },
                { name: "Red Hood", burst: "", class: "Attacker", code: "iron", company: "pilgrim", rarity: "ssr", weapon: "sr" },
                { name: "Rei", burst: "burst1", class: "defender", code: "water", company: "tetra", rarity: "ssr", weapon: "smg" },
                { name: "Rosanna", burst: "burst1", class: "attacker", code: "electric", company: "tetra", rarity: "ssr", weapon: "mg" },
                { name: "Rupee", burst: "burst2", class: "attacker", code: "iron", company: "tetra", rarity: "ssr", weapon: "ar" },
                { name: "Rupee: Winter Shopper", burst: "burst1", class: "defender", code: "electric", company: "tetra", rarity: "ssr", weapon: "ar" },
                { name: "Sakura", burst: "burst1", class: "supporter", code: "fire", company: "tetra", rarity: "ssr", weapon: "sr" },
                { name: "Scarlet", burst: "burst3", class: "attacker", code: "electric", company: "pilgrim", rarity: "ssr", weapon: "ar" },
                { name: "Shifty", burst: "burst1", class: "supporter", code: "water", company: "elysion", rarity: "ssr", weapon: "ar" },
                { name: "Signal", burst: "burst2", class: "attacker", code: "fire", company: "elysion", rarity: "ssr", weapon: "smg" },
                { name: "Sin", burst: "burst2", class: "defender", code: "electric", company: "missilis", rarity: "ssr", weapon: "ar" },
                { name: "Snow White", burst: "burst3", class: "attacker", code: "iron", company: "pilgrim", rarity: "ssr", weapon: "ar" },
                { name: "Soda", burst: "burst1", class: "supporter", code: "fire", company: "tetra", rarity: "ssr", weapon: "mg" },
                { name: "Soldier EG", burst: "burst1", class: "defender", code: "electric", company: "elysion", rarity: "r", weapon: "ar" },
                { name: "Soldier FA", burst: "burst2", class: "defender", code: "iron", company: "elysion", rarity: "r", weapon: "sg" },
                { name: "Soldier OW", burst: "burst1", class: "supporter", code: "fire", company: "elysion", rarity: "r", weapon: "smg" },
                { name: "Soline", burst: "burst1", class: "supporter", code: "iron", company: "elysion", rarity: "ssr", weapon: "smg" },
                { name: "Sugar", burst: "burst3", class: "attacker", code: "iron", company: "tetra", rarity: "ssr", weapon: "sg" },
                { name: "Tia", burst: "burst1", class: "defender", code: "iron", company: "tetra", rarity: "ssr", weapon: "sg" },
                { name: "Vesti", burst: "burst3", class: "attacker", code: "water", company: "elysion", rarity: "ssr", weapon: "rl" },
                { name: "Viper", burst: "burst2", class: "attacker", code: "iron", company: "missilis", rarity: "ssr", weapon: "rl" },
                { name: "Volume", burst: "burst1", class: "attacker", code: "wind", company: "tetra", rarity: "ssr", weapon: "smg" },
                { name: "Yan", burst: "burst1", class: "supporter", code: "fire", company: "tetra", rarity: "ssr", weapon: "rl" },
                { name: "Yulha", burst: "burst3", class: "attacker", code: "fire", company: "tetra", rarity: "ssr", weapon: "sr" },
                { name: "Yuni", burst: "burst1", class: "defender", code: "fire", company: "missilis", rarity: "ssr", weapon: "rl" },
            ],
            search: '',
        }
    },
    computed: {
        filteredNikkes() {
            // New computed property that filters Nikkes based on search term
            return this.nikkes.filter(nikke => {
                return nikke.name.toLowerCase().includes(this.search.toLowerCase())
            })
        }
    },
    mounted() {
        this.assignNikkeAttributes();
        this.loadAdScript();
    },
    methods: {
        assignNikkeAttributes() {
            this.nikkes.forEach(nikke => {
                nikke.image = `./img/${'_' + (nikke.name).replace(/:|\s/g, '').toLowerCase()}.webp`;
                nikke.url = { name: nikke.name + 'Page' };
                nikke.burst = `./nikkeBurst/${nikke.burst}.png`;
                nikke.class = `./nikkeClass/${nikke.class}.png`;
                nikke.code = `./nikkeCode/${nikke.code}.png`;
                nikke.company = `./nikkeCompany/${nikke.company}.webp`;
                nikke.rarity = `./nikkeRarity/${nikke.rarity}.png`;
                nikke.weapon = `./nikkeWeapon/${nikke.weapon}.png`;
            });
        },
        loadAdScript() {
            const adScript = document.createElement('script');
            adScript.type = 'text/javascript';
            adScript.async = true;
            adScript.src = "//www.topcreativeformat.com/b54a6410929f9429bf0ee227498a1ced/invoke.js";

            const adConfig = {
                key: 'b54a6410929f9429bf0ee227498a1ced',
                format: 'iframe',
                height: 300,
                width: 160,
                params: {}
            };

            window.atOptions = adConfig;
            document.getElementById('ad-container').appendChild(adScript);
        }
    }
};
</script>

<style>
.searchBar {
    width: 20vw;
    border: 3px solid #C69749;
    padding: 5px;
    height: 20px;
    border-radius: 15px;
    outline: none;
    color: #C69749;
    margin-bottom: 2vh;
    background-color: #282A3A;
}

@media only screen and (max-width: 767px) {
    .nikke {
        max-width: 85vw;
        justify-content: center;
    }

    .searchBar {
        width: 55vw;
    }
}
</style>