import './styles/style.css'
import { createApp } from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag";
import router from './router'
import { initializeApp } from "firebase/app";


const app = createApp(App);

app.use(router);

const firebaseConfig = {
    apiKey: "AIzaSyDZG7yh79v8p7DeTTdjeX50edJv2x-SvZM",
    authDomain: "nikke-website-883be.firebaseapp.com",
    projectId: "nikke-website-883be",
    storageBucket: "nikke-website-883be.appspot.com",
    messagingSenderId: "349964943365",
    appId: "1:349964943365:web:b38f08e47f44c9ef2c5c6c"
  };

initializeApp(firebaseConfig)

app.use(VueGtag, {
  config: { 
    id: "G-P55YV4JJBW",
  },
}, router);

createApp(App).use(router).mount('#app')
