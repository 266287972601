<template>
  <div id="wrapperHome" :style="{ backgroundImage: 'url(' + bgImage + ')'  }">
    <div class="jumbotron text-center">
      <h1>Welcome to Nikke Viewer</h1>
      <p>A gallery website for your favorite Nikkes</p>
      <p>and a danbooru image Viewer</p>
    </div>
    <div id="characterPageButton">
      <router-link to="/characters">
        <button type="button" class="block">Character Page</button>
      </router-link>
      <router-link to="/imageSearch">
        <button type="button" class="block">Danbooru Viewer </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  data() {
    return {
      bgImage: ''
    };
  },
  mounted() {
    const isMobile = window.innerWidth/window.innerHeight < 4/3;
    const num = Math.floor(Math.random() * (isMobile ? 5 : 15)) + 1;
    this.bgImage = isMobile
      ? `./homePageImgMobile/mobile-img${num}.jpg`
      : `./homePageImg/background_${num}.jpg`;
  }
};
</script>

<style>
.block {
  display: inline-block;
  width: 100%;
  border: none;
  background-color: #282A3A;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  color: #C69749;
  border-radius: 30px;
  border: 2px solid transparent;
}

.block:hover {
  border: 2px solid #C69749;
  transition: border 100ms ease-in-out;
}
</style>