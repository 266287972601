<template>
    <div id="charPage">
        <h1>{{ nikkeName }}</h1>
        <div class="checkbox-wrapper-2">
            <label for="g">
                <input id="g" type="checkbox" class="sc-gJwTLC ikxBAC" v-model="checkedValues" :value="'g'"
                    @change="ratingCheck" checked />
                General
            </label>
            <label for="s">
                <input id="s" type="checkbox" class="sc-gJwTLC ikxBAC" v-model="checkedValues" :value="'s'"
                    @change="ratingCheck" />
                Sensitive
            </label>
            <label for="q">
                <input id="q" type="checkbox" class="sc-gJwTLC ikxBAC" v-model="checkedValues" :value="'q'"
                    @change="ratingCheck" />
                Questionable
            </label>
            <label for="e">
                <input id="e" type="checkbox" class="sc-gJwTLC ikxBAC" v-model="checkedValues" :value="'e'"
                    @change="ratingCheck" />
                Explicit
            </label>
        </div>

        <div id="wrapper">
            <div class="nikke-page">
                <template v-for="(img, index) in images">
                    <template v-if="typeof img === 'string' && (img.endsWith('.webm') || img.endsWith('.mp4'))">
                        <video :key="index" :src="img" controls></video>
                    </template>
                    <template v-else>
                        <img :key="index" :src="img" :alt="img" @click="openModal(img, index)">
                    </template>
                </template>
            </div>
        </div>

        <!-- Modal Component -->
        <div v-if="showModal" class="modal">
            <div class="modal-content">
                <span @click="closeModal" class="close">&times;</span>
                <div class="arrows">
                    <img src="@/assets/left.png" @click="prevImage">
                    <img src="@/assets/right.png" @click="nextImage">
                </div>
                <img :src="selectedImage" alt="selectedImage">
                <!-- Display additional information here -->
                <p>Artist: {{ imageTags[selectedImageIndex] }}</p>
            </div>
        </div>
    </div>
</template>


<script>
import axios from "axios";

export default {
    data() {
        return {
            nikkeName: this.$route.params.name,
            images: [],
            checkedValues: ['g'],
            showModal: false,
            selectedImage: "",
            imageTags: [],
            page: 1,
            canCall: true,
            // canCallRatingCheck: true,
            selectedValues: ['g'],
        }
    },
    mounted() {
        this.getImages();
        this.loadMore();
    },
    methods: {
        convertName() {
            switch (this.nikkeName) {
                case "Anne: Miracle Fairy":
                    return "n102_(miracle_fairy)";
                case "Rupee: Winter Shopper":
                    return "rupee_(winter_shopper)";
                case "Snow White":
                    return "snow_white";
                case "Anis: Sparkling Summer":
                    return "anis_(sparkling_summer)";
                case "Helm: Aquamarine":
                    return "helm_(aqua_marine)";
                case "Mary: Bay Goddess":
                    return "mary_(bay_goddess)";
                case "Neon: Blue Ocean":
                    return "neon_(blue_ocean)";
                case "Red Hood":
                    return "red_hood";
                default:
                    return (this.nikkeName).toLowerCase();
            }
        },
        openModal(img, index) {
            this.showModal = true;
            this.selectedImage = img;
            this.selectedImageIndex = index;
        },
        closeModal() {
            this.showModal = false;

        },
        loadMore() {
            window.addEventListener('scroll', () => {
                let bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight) > (document.documentElement.offsetHeight - 100);
                if (bottomOfWindow && this.canCall) {
                    this.canCall = false;
                    this.getImages();
                    setTimeout(() => {
                        this.canCall = true;
                    }, 100);
                }
            });
        },
        prevImage() {
            if (this.selectedImageIndex > 0) {
                this.selectedImageIndex -= 1;
                this.selectedImage = this.images[this.selectedImageIndex];
            }
        },
        nextImage() {
            if (this.selectedImageIndex < this.images.length - 1) {
                this.selectedImageIndex += 1;
                this.selectedImage = this.images[this.selectedImageIndex];
            } else {
                this.getImages();
            }
        },
        async ratingCheck() {
            this.selectedValues = this.checkedValues.join(',');
            // if (this.canCallRatingCheck) {
            //     this.canCallRatingCheck = false;
            this.page = 1;
            this.images = [];
            this.imageTags = [];
            this.getImages();
            // setTimeout(() => {
            //     this.canCallRatingCheck = true;
            // }, 1000);
            // }
        },
        async getImages() {
            let tags = '';
            if (["himeno", "makima", "power"].includes(this.convertName())) {
                console.log(["himeno", "makima", "power"].includes(this.convertName()))
                tags = this.convertName() + "_(chainsaw_man) " + " rating:" + this.selectedValues;
            }
            else if (["a2", "2b", "pascal"].includes(this.convertName())){
                tags = this.convertName() + "_(nier:automata) " + " rating:" + this.selectedValues;
            }
             else {
                tags = this.convertName() + "_(nikke)" + " rating:" + this.selectedValues;
            }
            // tags = this.convertName() + "_(nikke)" + " rating:" + this.selectedValues;
            // const url = 'https://corsproxy.io/?' + encodeURIComponent('https://danbooru.donmai.us/posts.json?');
            const url = 'https://danbooru.donmai.us/posts.json?'

            axios.get(url, {
                params: {
                    "tags": tags,
                    "limit": 20,
                    "page": this.page++ + "&"
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            )
                .then((response) => {
                    const responseData = response.data.filter(post => post.file_url != null);
                    this.images = this.images.concat(responseData.map(post => post.file_url));
                    this.imageTags = this.imageTags.concat(responseData.map(post => post.tag_string_artist));
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    }
}
</script>



<style>
/* Position the left arrow icon */
.arrows img:first-child {
    position: absolute;
    left: -1vw;
    /* Adjust the left position to your preference */
    top: 50%;
    transform: translateY(35vh);
    opacity: .3;
    width: 13vw;
    height: 13vh;
}

/* Position the right arrow icon */
.arrows img:last-child {
    position: absolute;
    right: -1vw;
    /* Adjust the right position to your preference */
    top: 50%;
    transform: translateY(35vh);
    opacity: .3;
    width: 13vw;
    height: 13vh;
}


button {
    padding: 10px 20px;
    border-radius: 5px;
    margin: 10px 0;
}

/* checbox styling */
label {
    display: inline-block;
    margin: 0 .5vw;
    background-color: #282A3A;
    border-radius: 50px;
    padding: 5px 8px 10px;
    white-space: nowrap;
}

input {
    top: 5px;
}

.checkbox-wrapper-2 .ikxBAC {
    appearance: none;
    background-color: #63646e;
    border-radius: 72px;
    border-style: none;
    flex-shrink: 0;
    height: 20px;
    margin: 0;
    position: relative;
    width: 30px;
}

.checkbox-wrapper-2 .ikxBAC::before {
    bottom: -6px;
    content: "";
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
}

.checkbox-wrapper-2 .ikxBAC,
.checkbox-wrapper-2 .ikxBAC::after {
    transition: all 100ms ease-out;
}

.checkbox-wrapper-2 .ikxBAC::after {
    background-color: #282A3A;
    border-radius: 50%;
    content: "";
    height: 14px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 14px;
}

.checkbox-wrapper-2 input[type=checkbox] {
    cursor: default;
}

.checkbox-wrapper-2 .ikxBAC:checked {
    background-color: #C69749;
}

.checkbox-wrapper-2 .ikxBAC:checked::after {
    background-color: #282A3A;
    left: 13px;
}

.checkbox-wrapper-2 :focus:not(.focus-visible) {
    outline: 0;
}

#charPage {
    margin: 0 auto;
    margin-top: 5vh;
}

.h1 {
    margin: 10vh 10vw;
    text-align: center;
}

/* set the parent container to display as a grid */
.nikke-page {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    /* 3 columns */
    grid-gap: 10px;
    /* gap of 10px between images */
}

/* set the images to have a fixed width and height */
.nikke-page img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
}

.nikke-page video {
    width: 100%;
    height: auto;
    border-radius: 15px;
}

/* Modal Component */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    background-color: #282A3A;
    width: 95%;
    height: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    border: 2px solid #C69749;
}

.modal-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.close {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 42px;
    font-weight: bold;
    color: #ff0000;
    cursor: pointer;
}

.info {
    margin-top: 10px;
    display: none;
}

.modal img {
    width: 95%;
    height: 95%;
    object-fit: scale-down;
    inherits: border-radius;
}

.modal-content {
    padding-top: 2vh;
}


@media only screen and (max-width: 767px) {
    .modal {
        width: 95vw;
        height: 95vh;
    }

    label {
        display: inline-flexbox;
        font-size: 9px;
        margin: 0 .5vw;
        padding: 2px 8px 12px;
        white-space: nowrap;
        max-width: 40vw;
    }

    input {
        top: 6px;
    }

    .arrows img:first-child {
        position: absolute;
        left: -1vw;
        /* Adjust the left position to your preference */
        top: 50%;
        transform: translateY(35vh);
        opacity: .6;
        width: 13vw;
        height: 13vh;
    }

    /* Position the right arrow icon */
    .arrows img:last-child {
        position: absolute;
        right: -1vw;
        /* Adjust the right position to your preference */
        top: 50%;
        transform: translateY(35vh);
        opacity: .6;
        width: 13vw;
        height: 13vh;
    }

}
</style>
